@media(max-width: 1600px) {
    .swiper-slide-active .slider__home-slide .slider__home-img {
        animation: sliderImgs;
        animation-duration: 1.25s;
    }
    @keyframes sliderImgs {
        0% {
            transform: translateX(150px);
            opacity: 0;
        }
        100% {
            transform: translateX(25px);
            opacity: 1;
        }
    }
    .lastSliderTransform {
        animation: sliderImgs2 !important;
        animation-duration: 1.25s;
    }
    @keyframes sliderImgs2 {
        0% {
            transform: translateX(150px);
            opacity: 0;
        }
        100% {
            transform: translateX(57px);
            opacity: 1;
        }
    }

    .slider__home-slide {
        padding-left: 100px;
    }
    .slider__home-img {
        width: 800px;
        height: 430px;
        object-fit: cover;
    }
    .slider__home-slide-item h1 {
        margin-top: 100px;
    }
    .slider__home-img {
        transform: translateX(25px);
    }
    .lastSliderTransform {
        transform: translateX(57px);
    }
    .backinCatalog {
        left: 0;
    }
}
@media(max-width: 1500px) {
    .header__catal-item {
        font-size: 13px;
        font-weight: 300;
    }
    .contacts__wrapper {
        margin: 0 10px;
    }
    .about__main {
        padding-left: 10px;
        padding-right:10px ;
    }
    .prevArrowSlideProducts {
        left: 15px;
        z-index: 1;
    }
    .header__catalog-change-lang {
        margin-right: 100px;
    }
    .nextArrowSlideProducts {
        right: 15px;
        z-index: 1;
    }
}
@media(max-width: 1400px) {
    .header__catalog-all-categ {
        left: 72%;
        transform: translateX(-72%);
    }

    .header__logo {
        width: 240px;
    }
    .header__input-target {
        width: 450px;
    }
    .header__bg-logo {
        width: 240px;
    }
    .catalog__item-description ul li {
        list-style: none;
    }
}
@media (max-width: 1300px) {
    .info_index th,  .info_index tr {
        font-size: 10px;
    }
    .herc-slider-item {
        height: 185px;
    }
    .h225 {
        height: 185px;
    }
    .button__next-slider svg {
        transform: translateX(0);
    }
    .about__right-side {
        padding-left: 20px;
        margin-left: 0;
    }
    .slider__dot {
        width: 70px;
    }
    .products__slide-i img {
        width: 250px;
        height: 185px;
    }
    .products__slider {
        margin-bottom: 50px;
    }
    .products__title h4 {
        font-size: 23px;
    }

    .slider__home-img {
        width: 600px;
        height: 325px;
    }
    .slider__home-p-slider {
        margin-right: 0;
    }
    .slider__home-sec-p {
        font-size: 14px;
    }
    .slider__home-p-slider {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .lastSliderTransform {
        transform: translateX(85px);
    }
    .button__next-slider {
        width: 35px;
        height: 35px;
    }
    .button__next-slider svg {
        height: 17px;
        width: 17px;
    }
    .slider__home-slide-item h1 {
        margin-top: 80px;
        font-size: 25px;
        margin-bottom: 10px;
    }
}
@media (max-width: 1200px) {
    .hercules__section {
        padding-bottom: 40px;
    }
    .hercules__slider ul li {
        font-size: 13px;
    }
    .herculesImg {
        width: 350px !important;
    }
    .hercules__slider button {
        font-size: 14px;
    }
    .about__main {
        padding-top: 140px;
        padding-bottom: 10px;
    }
    .about__right-side {
        position: absolute;
        left: 0;
        right: 0;
        top: 128px;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.64);
    }
    .about__right-p {
        color: rgba(255, 255, 255, 0.82);
    }
    .about__right-side img {
        background-color: #fff;
        padding: 10px;
        border-radius: 20px;
    }
    .about__right-side h4 {
        color: #fff;
    }
    .about__left-side {
        opacity: 0.6;
    }
    .about__right-side {
        padding-left: 0;
    }
     .about__main {
            position: relative;
         justify-content: center;
     }

    .header__catalog-change-lang {
        margin-right: 50px;
    }
    .header__catalog-target {
        margin-left: 30px;
    }
    .header__catalog-target svg {
        height: 18px;
        width: 18px;
    }
    .header__logo {
        width: 200px;
    }
    .header__bg-logo {
        width: 200px;
    }
    .header__logo-img {
        height: 55px;
        top: 10px;
    }
    .slider__home-slide {
        padding-left: 40px;
    }
    .prevArrowSlideProducts {
        top: 100px;
    }
    .nextArrowSlideProducts {
        top: 100px;
    }
    .header__input-target {
        width: 400px;
    }
}
@media (max-width: 1100px) {
    .slide_catalog {
        width:200px;

    }
    .catalog__items {
        justify-content: center;
    }

    .slide_catalog p {
        font-size: 12px;
    }
    .slide_catalog img {
        width: 200px;
        height: 180px !important;
    }
}
@media (max-width: 1050px) {
    .header__catalog-all-categ.active {
        top: 90px;
    }
    .header__input {
        transform: translateX(-20px);
    }
    .about__main {
        padding-top: 130px;
        padding-bottom: 5px;
    }
    .contacts__wrapper-map {
        margin-bottom: 15px;
    }
    .catalog__main {
        padding-top: 120px;
    }

    .about__right-side {
        top: 118px;
    }


    .catalog__navig button {
        font-size: 12px;
    }
    .header__logo {
        width: 150px;
    }
    .header__bg-logo {
        width: 150px;
    }
    .slider__home-sec-p {
        font-size: 12px;
    }
    .slider__home-slide {
        padding-left: 20px;
    }
    .slider__home-p-slider {
        margin-bottom: 0;
    }
    .slider__home-slide-item h1 {
        margin-bottom: 0;
    }
    .slider__home-p-slider {
        font-size: 12px;
    }
    .products__slide-i p{
        font-size: 14px;
    }
    .subheader {

        height: 40px;
    }
    .header__input-target {
        width: 350px;
        font-size: 14px;
    }
    .header__input-target::placeholder {
        font-size: 14px !important;

    }

    .slider__home {
        padding-top: 119px;
    }
}
@media (max-width: 950px) {
    .hercules__slider ul li {
        font-size: 11px;
    }
    .herculesImg {
        width: 250px !important;
    }
    .swiper-slide-active .slider__home-slide .slider__home-img {
        animation: sliderImgs3;
        animation-duration: 1.25s;
    }
    @keyframes sliderImgs3 {
        0% {
            transform: translateX(150px);
            opacity: 0;
        }
        100% {
            transform: translateX(0px);
            opacity: 1;
        }
    }
    .about__footer {
        display: none;
    }
    .header__catalog-all-categ {
        display: flex;
        justify-content: center;
        left: 0;
        transform: translateX(0);
        right: 0;
        width: 100%;
    }
    .catalog__left {
        padding-left: 10px;
    }
    .slider__home-img {
        transform: translateX(180px);
    }
    .header__input {
        transform: translateX(0);
    }
    .slider__home-p-slider {
        width: 100%;
    }
    .slider__home-sec-p {
        margin-right: 0;
    }
    .slider__home-slide {
        position: relative;
        height: 325px;
        width: 100%;
    }
    .lastSliderTransform {
        right: -200px !important;
    }
    .slider__home-slide-item {
        margin-right: 350px;
    }
    .slider__home-img {
        position: absolute;
        right: -300px;
        transform: translateX(0);
        top: 0;
    }
}
@media (max-width: 900px) {

    .contacts__wrapper-items {
        flex-direction: column;
    }
    .contacts__wrapper-item {
        margin: 10px !important;
        width: 350px;
    }
    .contacts__wrapper-item svg {
        height: 45px;
        width: 45px;
    }

    .contacts__title {
        font-size: 25px;
        margin-top: 350px;
    }
    .contacts__description {
        padding-top: 1390px;
    }
}
@media (max-width: 880px) {

    .herculesImg {
        margin-bottom: 20px;
    }
    .hercules__slider button {
        font-size: 12px;
    }
    .catalog__left {
        display: none ;
    }
    .catalog__items {
        padding: 0 15px;
    }
    .contacts__background {
        background-size: 30% auto;
    }
    .catalog__left {
        display: none;
    }

    .catalog_down {
        display: flex;
        justify-content: center;
    }
    .catalog__items {
        justify-content: center;
    }
}
@media (max-width: 820px) {
    .prevArrowSlideProducts, .nextArrowSlideProducts {
        top: 80px;
    }
    .herc-slider-item {
        height: 150px;
    }
    .h225 {
        height: 150px;
    }
    .about__left-side img {
        width: 600px;
    }
    .lastSliderTransform {
        right: -300px !important;
    }
    .products__slide-i img {
        width: 200px;
        height: 150px;
    }
    .products__slide-i p {
        font-size: 10px;
    }
    .about__description-main {
        padding: 0 20px;
    }
    .about__right-p {
        padding: 0 10px;
        font-size: 14px;
        margin: 0;
    }
}
@media (max-width: 750px) {
    .header__catalog-all-categ.active {
        top: 70px;
    }
    .catalog__item-title {
        max-width: 500px;
    }
    .catalog_down {
        margin: 10px 0;
    }
    .catalog__main {
        padding-top: 100px;
    }
    .results__under-header-find.active {
        top: 59px;
    }
    .catalog__description-buttons {
        flex-direction: column;
        align-items: center;
    }
    .header__catal-item {
        padding: 0 10px;
    }
    .about__main {
        padding-top: 110px;
        padding-bottom: 5px;
    }
    .download_catalog {
        margin-top: 0;
    }
    .slide_catalog {
        margin: 5px;
    }
    .about__right-side {
        top: 100px;
    }
    .header__input-target {
        padding: 10px;
        padding-left: 40px;
        width: 280px;
    }
    .search {
        height: 18px;
        width: 18px;
        left: 10px !important;
    }
    header {
        height: 60px;
    }
    .header__logo {
        height: 60px;
        width: 120px;
    }
    .header__bg-logo {
        height: 60px;
        width: 120px;
    }
    .header__logo-img {
        height: 40px;
        left: 20px;
    }
    .header__catalog-target {
        margin-left: 10px;
    }
    .header__catalog-change-lang {
        margin-right: 20px;
    }
    .slider__home {
        padding-top: 100px;
    }
    footer p {
        font-size: 13px;
    }
}
@media (max-width: 690px) {
    td, table.info_index th {
        padding: 5px;
        font-size: 9px;
    }

    .catalog__item-description {
        padding: 0 15px 15px 15px;
    }
    .backinCatalog {
        left: 15px;
    }
    .catalog__item-title {
        font-size: 25px;
    }
    .slide_catalog {
        width: 160px;
    }
    .slide_catalog img {
        height: 130px !important;
        width: 160px;
    }
    .slide_catalog p {
        margin: 7px 0 10px 0;

    }
    .catalog__items {
        padding: 0;
    }
    .slider__home-slide-item h1 {
        margin-top: 60px;
    }
    .slider__home-slide-item {
        margin-right: 310px;
    }
}
@media (max-width: 630px) {

    .close__search-phone {
        display: block;
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    .openClose {
        position: static;
        z-index: 1;
        opacity: 1;
    }
    .results__under-header-find.active {
        top: 54px;
    }
    .search__wrapper.active {
        border-radius: 22px;
        padding: 6px 14px;
    }
    .search__wrapper.active input {
        width: 220px;
        border-radius: 14px;
        outline: none;

        border: none;
        padding: 10px 10px 10px 15px;
        margin-left: 10px;
        opacity: 1;
        position: static;
    }
    .header__changeLangPhone {
        position: absolute;
        bottom: -36px;

        padding: 10px 0;


        transform: translateX(40px);
        display: flex !important;

        background: rgba(232, 232, 232, 0.55);
        -webkit-backdrop-filter: blur(5.5px);
        backdrop-filter: blur(5.5px);
    }
    .header__changeLangPhone .header__pt {
        padding-left: 20px;
        font-size: 13px;
    }
    .header__pt {
        padding-bottom: 5px;
    }
    .header__changeLangPhone .header__catalogEn-open.active {
        background: rgba(232, 232, 232, 0.55);
        -webkit-backdrop-filter: blur(5.5px);
        backdrop-filter: blur(5.5px);
        top: 36px;
        width: 100%;
        left: 0;
        box-shadow: none;
        height: 26px;
    }
    .header__changeLangPhone .header__catalog-change-lang {
        display: flex;
        align-items: center;
        font-size: 13px;
    }

    .about__right-side {
        top: 90px;
    }
    .phoneButton {
        display: flex !important;
        margin: 0  10px 0 0 !important;
    }
    .header__catalogEn {
        display: none;
    }
    .header__catalog-target {
        display: none;
    }
    .about__left-side img {
        width: 450px;
    }
    .about__right-side h4 {
        margin-top: 60px;
    }
    .header__input-target {
        display: none;
    }
    .search {
        position: static !important;

    }
    .hiddensvg {
        position: absolute !important;
        opacity: 0;
        z-index: -1;
    }
    header {
        height: 55px;
    }
    .header__logo-img {
        top: 7px;
        left: 10px;
    }
    .header__bg-logo {
        width: 110px;
        height: 55px;
    }
    .header__logo {
        width: 110px;
        height: 55px;
    }
    .slider__home {
        padding-top: 95px;
    }
    .search__wrapper {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.07);
        padding: 8px;

        border-radius: 50%;
    }
    .header__input {
        transform: translateX(0px);
    }
    .header__catalog-target {
        margin-left: 20px;
    }
    .slider__home-img {
        right: -370px;
    }
    .slider__home-slide-item {
        margin-right: 230px;
    }
    .lastSliderTransform {
        right: -390px !important;
    }


}
@media (max-width: 600px) {
    .catalog__item-title {
        max-width: 450px;
    }
    .backinCatalog {
        top: 30px;
    }


    .header__changeLangPhone {

        transform: translateX(30px);
    }


}
@media (max-width: 550px) {

    .herc-slider-item {
        margin-right: 20px;
    }
    .header__changeLangPhone {
        transform: translateX(-20px);
    }
    .video__catal-item {
        height: 250px;
    }
    .results__under-header-find.active {
        right: 0;
        left: 0;
        width: 100%;
        transform: translateX(0);
    }
    .results__under-header-find p {
        font-size: 14px;
    }
    .about__description-title-main-second {
        margin-bottom: 60px;
    }
    .contacts__description {
        padding-top: 1360px;
    }
    .slider__home-img {
        height: 280px;
    }
    .slider__home-slide {
        height: 280px;
    }
    .lastSliderTransform {
        right: -400px !important;
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .catalog__item-description ul li {
        font-size: 13px;
    }
    .catalog__item-description h5 {
        font-size: 16px;
    }
    .contacts__wrapper {
        padding: 0px;
    }
    .catalog__sizes {
        font-size: 12px;
    }
    .about__right-side h4 {
        font-size: 30px;
    }
    .about__right-side img {
        width: 50px;
        height: 50px;
    }
    .about__main {
        padding-top: 105px;
    }

    .about__description-title-main, .about__description-title-main-second {
        font-size: 14px;
    }
    .chill {
        display: none;
    }
    .products__title h4 {
        font-size: 21px;
    }
    .slider__home-img {
        right: -400px;
    }
    .slider__home-slide-item {
        margin-right: 200px;
    }
    footer p {
        font-size: 11px !important;
    }
}
@media (max-width: 470px) {
    .hercules__slider {
        display: flex;
        flex-direction: column;
    }
    .slider__home-img {
        height: 250px;
    }
    .herc-slider-item {
        width: 100%;
        margin-right: 0;

        height: 200px;
    }
    .herc-slider-item img {
        width: 100%;
        object-fit: cover;
        height: 200px;
    }
    .h225 {
        width: 100%;
        margin-right: 0;
        display: none;
        height: 200px;
    }

    .slider__home-slide {
        height: 250px;
    }
    .header__bg-logo {
        border-radius: 0 0 35px 0;
        width: 80px;
        overflow: hidden;
    }
    .slider__home-sec-p {
        display: none;
    }
    .slider__catal-item {
        width: 300px;
    }
    .slider__catal-title-wrapper {
        font-size: 14px;
    }
    .about__left-side img {
        width: 300px;
    }
    .about__right-side h4 {
        margin-top: 30px;
        margin-bottom: 7px;
    }
    .catalog__item-description ul li{
        font-size: 12px;
    }
    .about__right-p {
        font-size: 12px;
        line-height: 17px;
    }
    .about__description-main p {
        font-size: 14px;

    }
    .slider__home-nav button:first-child{
        transform: translateX(-20px);
    }
    .slider__home-nav button:last-child{
        transform: translateX(20px);
    }
    .slider__home-slide {
        padding-left: 10px;
    }
    .header__input {
        transform: translateX(0);
    }
    .lastSliderTransform {
        transform: translateX(50px) !important;
    }
    .slider__home-slide-item {
        margin-right: 170px;
    }
    .slider__home-img {
        right: -440px;
    }
    .slider__dot {
        margin:0 3px ;
    }
}
@media (max-width: 420px) {
    .header__changeLangPhone {
        transform: translateX(-50px);
    }
    td, table.info_index th {
        padding: 3px;
        font-size: 7px;
    }
}
@media (max-width: 390px) {
    .hercules__title h4{
        font-size: 18px !important;
    }
    .header__changeLangPhone {

        transform: translateX(-70px);
    }
    .contacts__wrapper-item {
        width: 300px;
        padding: 14px;
    }
    .contacts__desc-wrapper  {
        font-size: 14px;
        margin: 10px 0;
    }
    .contacts__wrapper-item p:last-child {
        font-size: 14px;
    }
    .about__description-main p {
        font-size: 12px;
    }
    .header__catalogEn {
        display: none;
    }


    .lastSliderTransform {
        transform: translateX(80px) !important;
    }
    .slider__home-img {
        right: -476px;
    }
    .slider__home-slide-item {
        margin-right: 120px;
    }

}
@media (max-width: 350px) {
    .contacts__description {
        padding-top: 1360px;
    }

    .slide_catalog {
        width: 130px;
    }
    .slide_catalog img {
        width: 130px;
        height: 120px !important;
    }
    .slider__home-slide-item h1 {
        font-size: 20px;
    }
    .slider__home-p-slider {
        font-size: 11px;
        line-height: 20px;
    }
    .slider__home-sec-p {
        font-size: 11px;
        line-height: 20px;
    }
    .slider__home-nav button:first-child {
        transform: translateX(0);
    }
    .slider__home-nav button:last-child {
        transform: translateX(0);
    }
}
@media (min-width:880px ) {

    .hiddenDesc {
        display: none !important;
    }
}
@media (max-width: 330px) {
    .about__description-main {
        padding: 0 5px ;
    }
    .about__description-main p {
        font-size: 10px;
        line-height: 20px;
    }
    .about__right-p {
        font-size: 10px;
        line-height: 20px;
    }
    .contacts__wrapper-item {
        width: 280px;
    }
    .about__right-side h4 {
        font-size: 20px;
        margin-bottom: 4px;
    }
    .about__right-side img {
        height: 40px;
        width: 40px;
        border-radius: 10px;
    }
    footer p {
        font-size: 9px !important;
    }
    .products__title h4 {
        font-size: 19px;
    }
    .slider__dot {
        width: 60px;
    }
    .slider__home-slide-item h1 {
        font-size: 19px;
    }
}